import { alpha } from '@mui/material/styles';

import type { BatonTheme } from 'theme';

const getBtnColors = (background: string, borderColor = background) => ({
  background,
  borderColor,
});

const styles = (theme: BatonTheme, _, classes: { [key: string]: string }) => {
  const { baseUnit, palette, typography, mixins } = theme;
  const height = 32;
  const smallHeight = 24;
  const disabledSolid = getBtnColors(palette.status.grey[2]);

  const disabledOutline = {
    ...getBtnColors(palette.background.secondary, palette.border.secondary),
    color: palette.text.disabled,
  };

  const disabledGhost = {
    ...getBtnColors(palette.background.secondary),
    color: palette.text.disabled,
  };

  return {
    button: {
      ...typography.body2,
      background: palette.status.orange[0],
      border: `1px solid ${palette.status.orange[0]}`,
      borderRadius: 4,
      color: '#FFF',
      height,
      lineHeight: 'normal',
      padding: `0 ${baseUnit * 3}px`,
      transitionProperty: 'background, border-color, color',
      transitionDuration: '200ms',
      transitionTimingFunction: 'ease-in',
      '&:hover': getBtnColors(alpha(palette.status.orange[0], 0.9), 'transparent'),
      '&:active': getBtnColors(alpha(palette.status.orange[0], 0.9), 'transparent'),
      '&:disabled': disabledSolid,
    },
    small: {
      ...typography.caption1,
      height: smallHeight,
      padding: `0 ${baseUnit}px`,
    },
    focus: {
      boxShadow: `0px 0px 2px 2px ${palette.primary.main}`,
    },

    // Components
    icon: {
      order: 1,
      flex: '0 0 24px',
      height: baseUnit * 6,
      width: baseUnit * 6,
      margin: `0 ${baseUnit / 2}px 0 -${baseUnit * 2}px`,
    },
    label: {
      ...mixins.truncate,
      order: 2,
    },

    // Variants
    outline: {
      ...getBtnColors('#FFF', palette.primary.main),
      color: palette.primary.main,
      '&:hover': getBtnColors(palette.status.blue[5], palette.primary.main),
      '&:active': getBtnColors(palette.status.blue[4], palette.primary.main),
      '&:disabled': disabledOutline,
    },
    ghost: {
      ...getBtnColors(palette.background.secondary),
      color: palette.primary.dark,
      '&:hover': getBtnColors(palette.status.blue[5]),
      '&:active': getBtnColors(palette.status.blue[5], palette.status.orange[0]),
      '&:disabled': disabledGhost,
    },

    // Color schemes
    neutral: {
      ...getBtnColors(palette.button.neutral),
      color: '#FFF',
      '&:hover': getBtnColors(palette.button.neutralHover),
      '&:active': getBtnColors(palette.button.neutral),
      [`&.${classes.outline}`]: {
        ...getBtnColors('#FFF', palette.button.neutral),
        color: palette.button.neutral,
        '&:hover': {
          background: palette.status.grey[4],
        },
        '&:active': {
          background: palette.status.grey[3],
        },
        '&:disabled': disabledOutline,
      },
      [`&.${classes.ghost}`]: {
        ...getBtnColors(palette.background.secondary),
        color: palette.button.neutral,
        '&:hover': {
          background: palette.status.grey[4],
        },
        '&:active': getBtnColors(palette.status.grey[3], palette.button.neutral),
        '&:disabled': disabledGhost,
      },
    },
    positive: {
      ...getBtnColors(palette.status.green[0]),
      color: '#FFF',
      '&:hover': getBtnColors(palette.button.positiveHover),
      '&:active': getBtnColors(palette.status.green[0]),
      [`&.${classes.outline}`]: {
        ...getBtnColors('#FFF', palette.button.positive),
        color: palette.button.positive,
        '&:hover': {
          background: palette.status.green[4],
        },
        '&:active': {
          background: palette.status.green[3],
        },
        '&:disabled': disabledOutline,
      },
      [`&.${classes.ghost}`]: {
        ...getBtnColors(palette.background.secondary),
        color: palette.button.positive,
        '&:hover': {
          background: palette.status.green[5],
        },
        '&:active': getBtnColors(palette.status.green[4], palette.button.positive),
        '&:disabled': disabledGhost,
      },
    },
    negative: {
      ...getBtnColors(palette.status.red[0]),
      color: '#FFF',
      '&:hover': getBtnColors(palette.button.negativeHover),
      '&:active': getBtnColors(palette.status.red[0]),
      [`&.${classes.outline}`]: {
        ...getBtnColors('#FFF', palette.status.red[0]),
        color: palette.status.red[0],
        '&:hover': {
          background: palette.status.red[5],
        },
        '&:active': {
          background: palette.status.red[4],
        },
        '&:disabled': disabledOutline,
      },
      [`&.${classes.ghost}`]: {
        ...getBtnColors(palette.background.secondary),
        color: palette.status.red[0],
        '&:hover': {
          background: palette.status.red[5],
        },
        '&:active': getBtnColors(palette.status.red[4], palette.status.red[0]),
        '&:disabled': disabledGhost,
      },
    },
    csOrange: {
      ...getBtnColors(palette.status.orange[0]),
      color: '#FFF',
      '&:hover': getBtnColors(palette.button.csOrangeHover),
      '&:active': getBtnColors(palette.button.csOrangeHover),
    },
    csDark: {
      ...getBtnColors(palette.primary.dark),
      color: '#FFF',
      '&:hover': getBtnColors(palette.button.csDarkHover),
      '&:active': getBtnColors(palette.button.csDarkHover),
    },

    // Icons
    withRightIcon: {
      [`& .${classes.icon}`]: {
        order: 2,
        margin: `0 -${baseUnit * 2}px 0 ${baseUnit / 2}px`,
      },
      [`& .${classes.label}`]: {
        order: 1,
      },
    },
    withIconOnly: {
      padding: `0 ${baseUnit}px`,
      [`& .${classes.icon}`]: {
        margin: 0,
      },
    },
  };
};

export default styles;

const initial = {
  templates: {},
};

// eslint-disable-next-line default-param-last
const templates = (state = initial, action) => {
  switch (action.type) {
    case 'TEMPLATES_FETCH_SUCCESS':
      return { ...state, templates: action.templates };

    case 'TEMPLATE_FETCH_SUCCESS': {
      const { template } = action;

      return { ...state, templates: { ...state.templates, [template.id]: template } };
    }

    case 'TEMPLATE_CREATE_SUCCESS': {
      const { template } = action;

      return { ...state, templates: { ...state.templates, [template.id]: template } };
    }

    case 'TEMPLATE_DELETE_SUCCESS': {
      const { templateId } = action;
      const { [templateId]: deletedTemplate, ...otherTemplates } = state.templates;

      return { ...state, templates: otherTemplates };
    }

    case 'TEMPLATE_LINK_ADD_SUCCESS': {
      const { templateId, link } = action;
      const template = state.templates[templateId];
      const templateLinks = template.links;
      const updatedLinks = { ...templateLinks, [link.id]: link };
      const updatedTemplate = { ...template, links: updatedLinks };

      return { ...state, templates: { ...state.templates, [templateId]: updatedTemplate } };
    }

    case 'TEMPLATE_LINK_DELETE_SUCCESS': {
      const { templateId, linkId } = action;
      const template = state.templates[templateId];
      const { [linkId]: deletedLink, ...otherLinks } = template.links;
      const updatedTemplate = { ...template, links: otherLinks };

      return { ...state, templates: { ...state.templates, [templateId]: updatedTemplate } };
    }

    case 'TEMPLATE_LINK_REPLACE_SUCCESS': {
      const { templateId, previousLinkId, link } = action;
      const template = state.templates[templateId];
      const { [previousLinkId]: previousLink, ...otherLinks } = template.links;
      const updatedLinks = { ...otherLinks, [link.id]: link };
      const updatedTemplate = { ...template, links: updatedLinks };

      return { ...state, templates: { ...state.templates, [templateId]: updatedTemplate } };
    }

    case 'TEMPLATE_PREVEW_DATA_FETCH_SUCCESS': {
      const { templateId, previewData } = action;
      const template = state.templates[templateId];
      const updatedTemplate = { ...template, previewData };

      return { ...state, templates: { ...state.templates, [templateId]: updatedTemplate } };
    }

    default:
      return state;
  }
};

export default templates;

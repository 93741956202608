const persistedUI = JSON.parse(localStorage.getItem('ui'));
const persistedDataBar = persistedUI?.projectsView?.showDataBar;
const persistedView = persistedUI?.projectsView?.view;
const persistedCollapsedFilters = persistedUI?.projectsView?.collapsedFilters;
const hasPersistedCollapsedFilters = persistedCollapsedFilters
  && persistedCollapsedFilters?.length;

const initialState = {
  collapsedFilters: hasPersistedCollapsedFilters ? persistedCollapsedFilters : [],
  view: persistedView || 'list',
  hideEmptyColumns: false,
  showDataBar: persistedDataBar ?? true,
  hasDismissedRefreshSnackbar: false,
  isProjectsBoardViewHeaderStuck: false,
};

// eslint-disable-next-line default-param-last
const projectsView = (state = initialState, action) => {
  switch (action.type) {
    case 'PROJECTS_VIEW_SET_COLLAPSED_FILTERS': {
      return { ...state, collapsedFilters: action.filters };
    }
    case 'PROJECTS_VIEW_SET_VIEW':
      return { ...state, view: action.view };
    case 'PROJECTS_VIEW_HIDE_EMPTY_COLUMNS':
      return { ...state, hideEmptyColumns: action.hideEmptyColumns };
    case 'PROJECTS_VIEW_SHOW_DATA_BAR':
      return { ...state, showDataBar: action.showDataBar };
    case 'PROJECTS_SET_HAS_DISMISSED_REFRESH_SNACKBAR':
      return { ...state, hasDismissedRefreshSnackbar: action.hasDismissedRefreshSnackbar };
    case 'PROJECTS_VIEW_SET_BOARD_VIEW_HEADER_STUCK':
      return { ...state, isProjectsBoardViewHeaderStuck: action.isProjectsBoardViewHeaderStuck };
    default:
      return state;
  }
};

export default projectsView;

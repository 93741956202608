const initial = {
  users: {},
};

const users = (state = initial, action) => {
  switch (action.type) {
    case 'USERS_FETCH_SUCCESS':
      return { ...state, users: action.users };

    case 'USER_CREATE_SUCCESS':
      return { ...state, users: { ...state.users, [action.user.id]: action.user } };

    case 'USER_DELETE_SUCCESS': {
      const { users } = state;
      const { userId } = action;

      delete users[userId];

      return { ...state, users };
    }

    default:
      return state;
  }
};

export default users;

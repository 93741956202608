const initial = {
  projectRates: {},
};

const projectRates = (state = initial, action) => {
  switch (action.type) {
    case 'PROJECT_RATE_UPDATE_SUCCESS': {
      const { projectId, projectRate } = action;
      const current = state.projectRates[projectId];
      const updatedRates = { ...current, [projectRate.id]: projectRate };

      return { ...state, projectRates: { ...state.projectRates, [projectId]: updatedRates } };
    }

    case 'PROJECT_RATES_FETCH_SUCCESS': {
      const { projectId, projectRates } = action;
      const newProjectRates = { ...state.projectRates, [projectId]: projectRates };

      return { ...state, projectRates: newProjectRates };
    }

    default:
      return state;
  }
};

export default projectRates;

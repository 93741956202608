const initial = {};

const projectDashboardCards = (state = initial, action) => {
  switch (action.type) {
    case 'PROJECT_DASHBOARD_CARDS_FETCH_SUCCESS': {
      const { projectId, projectDashboardCards } = action;

      return { ...state, [projectId]: projectDashboardCards };
    }

    default:
      return state;
  }
};

export default projectDashboardCards;

const initial = {};

const templateSettings = (state = initial, action) => {
  switch (action.type) {
    case 'TEMPLATE_SETTINGS_FETCH_SUCCESS': {
      const { templateId, templateSettings } = action;

      return { ...state, [templateId]: templateSettings };
    }

    case 'TEMPLATE_SETTING_UPDATE_SUCCESS': {
      const { templateId, setting } = action;
      const updatedTemplateSettings = { ...state[templateId], [setting.id]: setting };

      return { ...state, [templateId]: updatedTemplateSettings };
    }

    default:
      return state;
  }
};

export default templateSettings;

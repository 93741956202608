import { forwardRef } from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import { makeStyles } from 'tss-react/mui';

import type { ButtonBaseProps } from '@mui/material';

import { BUTTON_COLOR_SCHEMES } from 'components/Buttons/Button/buttonConstants';
import styles from './TextButtonStyles';

type Props = ButtonBaseProps & {
  colorScheme?: typeof BUTTON_COLOR_SCHEMES[number]
  size?: 'small' | 'medium'
};

const useStyles = makeStyles({ name: 'TextButton' })(styles);

const TextButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { children, className, colorScheme = 'brand', size, ...otherProps } = props;
  const { classes, cx } = useStyles();

  return (
    <ButtonBase
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      className={cx(classes.root, classes[colorScheme], classes[size], className)}
      classes={{ focusVisible: classes.focus }}
      ref={ref}
      {...otherProps}
    >
      {children}
    </ButtonBase>
  );
});

TextButton.displayName = 'TextButton';

export default TextButton;

const persistedUI = JSON.parse(localStorage.getItem('ui'));

const initialState = {
  hasDismissedRefreshSnackbar: false,
  collapsedGroupTables: persistedUI?.startingLineView?.collapsedGroupTables ?? [],
  filterHeaderHeight: 0,
  showInsights: persistedUI?.startingLineView?.showInsights ?? false,
  tasksByDepartmentCountKey: persistedUI?.startingLineView?.tasksByDepartmentCountKey ?? 'taskCount',
  tasksByDaysOfWeekCountKey: persistedUI?.startingLineView?.tasksByDaysOfWeekCountKey ?? 'taskCount',
  timeTrackingModalProps: {
    open: false,
  },
};

// eslint-disable-next-line default-param-last
const startingLineView = (state = initialState, action) => {
  switch (action.type) {
    case 'STARTING_LINE_SET_HAS_DISMISSED_REFRESH_SNACKBAR':
      return { ...state, hasDismissedRefreshSnackbar: action.hasDismissedRefreshSnackbar };
    case 'STARTING_LINE_SET_COLLAPSED_GROUP_TABLES':
      return { ...state, collapsedGroupTables: action.collapsedGroupTables };
    case 'STARTING_LINE_SET_FILTER_HEADER_HEIGHT':
      return { ...state, filterHeaderHeight: action.filterHeaderHeight };
    case 'STARTING_LINE_SET_INSIGHTS_PANEL':
      return { ...state, showInsights: action.showInsights };
    case 'STARTING_LINE_SET_TASKS_BY_DEPARTMENT_COUNT_KEY':
      return { ...state, tasksByDepartmentCountKey: action.countKey };
    case 'STARTING_LINE_SET_TASKS_BY_DAYS_OF_WEEK_COUNT_KEY':
      return { ...state, tasksByDaysOfWeekCountKey: action.countKey };
    case 'STARTING_LINE_SET_TIME_TRACKING_MODAL': {
      const { timeTrackingModalProps } = action;

      return { ...state, timeTrackingModalProps };
    }
    default:
      return state;
  }
};

export default startingLineView;

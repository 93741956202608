/**
 * WARNING:
 * Trying to spread the entirety of the typography object causes JSS
 * To print empty style tags in the head every time the component is rendered
 * typing each style individually seems to solve the issue
 */

import type { BatonTheme } from 'theme';

const styles = (theme: BatonTheme) => {
  const { typography } = theme;
  const {
    body1,
    body2,
    body3,
    caption1,
    caption2,
    display1,
    display2,
    display3,
    display4,
    display5,
    h1,
    h2,
    overline1,
    overline2,
  } = typography;

  return {
    body1,
    body2,
    body3,
    caption1,
    caption2,
    display1,
    display2,
    display3,
    display4,
    display5,
    h1,
    h2,
    overline1,
    overline2,
  };
};

export default styles;

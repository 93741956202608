const persistedUI = JSON.parse(localStorage.getItem('ui'));
const persistedOutline = persistedUI?.projectTimelineOutlineColumns;

const initialState = {
  template: {
    badges: persistedOutline?.template?.badges ?? true,
    estimatedTime: persistedOutline?.template?.estimatedTime ?? true,
    duration: persistedOutline?.template?.duration ?? true,
    startDay: persistedOutline?.template?.startDay ?? true,
  },
  project: {
    badges: persistedOutline?.project?.badges ?? true,
    estimatedTime: persistedOutline?.project?.estimatedTime ?? true,
    dueDate: persistedOutline?.project?.dueDate ?? true,
    startDate: persistedOutline?.project?.startDate ?? true,
    status: persistedOutline?.project?.status ?? true,
  },
};

// eslint-disable-next-line default-param-last
const projectTimelineOutlineColumns = (state = initialState, action) => {
  switch (action.type) {
    case 'PROJECT_TIMELINE_OUTLINE_COLUMNS_TOGGLE': {
      const { parentKey, column } = action;
      const parent = state[parentKey];
      const currentValue = parent[column];
      const updatedParent = { ...parent, [column]: !currentValue };

      return { ...state, [parentKey]: updatedParent };
    }
    default:
      return state;
  }
};

export default projectTimelineOutlineColumns;

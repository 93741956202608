const initial = {};

const projectHolidays = (state = initial, action) => {
  switch (action.type) {
    case 'PROJECT_HOLIDAYS_FETCH_SUCCESS': {
      const { projectId, holidays } = action;
      return { ...state, [projectId]: holidays };
    }

    case 'PROJECT_HOLIDAY_UPDATE_SUCCESS': {
      const { projectId, holiday } = action;
      const updatedProjectHolidays = { ...state[projectId], [holiday.id]: holiday };

      return { ...state, [projectId]: updatedProjectHolidays };
    }

    default:
      return state;
  }
};

export default projectHolidays;

const initialState = {
  open: false,
  component: null,
  props: {},
};

// eslint-disable-next-line default-param-last
const contentDrawer = (state = initialState, action) => {
  switch (action.type) {
    case 'CONTENT_DRAWER_OPEN': {
      const { component, props } = action;

      return { ...state, open: true, component, props };
    }
    case 'CONTENT_DRAWER_CLOSE':
      return { ...state, open: false, component: null, props: {} };
    default:
      return state;
  }
};

export default contentDrawer;

/* eslint-disable default-param-last */
const initial = {
  milestones: {},
};

const milestones = (state = initial, action) => {
  switch (action.type) {
    case 'MILESTONES_FETCH_SUCCESS': {
      const newMilestones = { ...state.milestones, [action.projectId]: action.milestones };
      return { ...state, milestones: newMilestones };
    }

    case 'MILESTONES_DELETE_SUCCESS': {
      const { projectId, milestoneId } = action;
      const { [projectId]: projectMilestones, ...otherProjectsMilestones } = state.milestones;
      const { [milestoneId]: deletedMilestone, ...remainingMilestones } = projectMilestones;

      return {
        ...state,
        milestones: {
          ...otherProjectsMilestones,
          [projectId]: remainingMilestones,
        },
      };
    }

    case 'MILESTONE_SAVE_SUCCESS': {
      // eslint-disable-next-line max-len
      const projMilestones = { ...state.milestones[action.projectId], [action.milestone.id]: action.milestone };
      const newMilestones = { ...state.milestones, [action.projectId]: projMilestones };
      return { ...state, milestones: newMilestones };
    }

    default:
      return state;
  }
};

export default milestones;

const initial = {
  commit: '',
  static_asset_checksum: '',
  initialCheckCompleted: false,
  newCodeDetected: false,
  checksumAtDismissal: '',
};

// eslint-disable-next-line default-param-last
const deploy = (state = initial, action) => {
  switch (action.type) {
    case 'DEPLOY_STATUS_UPDATE': {
      const { config } = action;

      if (!state.initialCheckCompleted) {
        return {
          ...state,
          static_asset_checksum: config.static_asset_checksum,
          checksumAtDismissal: config.static_asset_checksum,
          commit: config.commit,
          initialCheckCompleted: true,
        };
      }

      return {
        ...state,
        static_asset_checksum: config.static_asset_checksum,
        commit: config.commit,
        newCodeDetected: true,
      };
    }

    case 'DEPLOY_SET_CHECKSUM_AT_DISMISSAL':
      return { ...state, checksumAtDismissal: action.checksum };

    default:
      return state;
  }
};

export default deploy;

import React from 'react';
import PropTypes from 'prop-types';

import theme from 'theme';

function TrackAndField({ color = theme.palette.brand2, ...props }) {
  return (
    <svg
      width="101%"
      fill="none"
      viewBox="0 0 1444 761"
      {...props}
    >
      <g opacity={0.48} stroke={color} strokeWidth={1.75} strokeMiterlimit={10}>
        <path d="M2.797 759.285s461.468-174.512 959.818-176.312c498.345-1.799 479.455 0 479.455 0M1 657.637s443.477-167.316 964.316-170.015c520.834-2.699 476.764 0 476.764 0M2.797 555.087s443.477-162.818 960.717-162.819h478.556M1 452.537s454.272-156.521 962.517-156.521h478.563M1 347.29s456.071-146.627 962.517-146.627c506.443 0 478.563-.899 478.563-.899M1 249.24s447.975-147.526 962.517-147.526h478.563M1 147.592S484.957 1.865 963.517 1.865h478.563" />
        <path d="M695.552 15.445S964.418 325.7 963.069 583.42V2.005M686.34 503.941l-10.264-95.937M412.838 546.228l-20.969-94.669M383.91 453.148l-18.972-94.263M143.055 611.884l-28.974-92.42M84.898 426.641l-30.126-95.244" />
        <path
          d="M191.513 700.21c.189-.296-19.601-59.667-19.601-59.667l17.091 8.096M171.912 587.468v-68.366M170.115 486.72v-68.366M165.619 286.121v-68.366M168.314 387.77v-68.366M554.332 229.315l-7.305-61.934-12.593 13.493M248.571 184.237l-14.59-61.835-9.895 14.393M863.218 297.984l-2.249-59.541-12.594 13.493M1166.66 392.266l.15-61.169-12.59 12.594M1044.47 559.583v-46.776M1044.47 462.432v-46.777M1044.47 367.081v-46.777M1044.47 271.73v-46.776M1044.47 173.678v-46.776M1044.47 75.625V28.848"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

TrackAndField.propTypes = {
  color: PropTypes.string,
};

export default TrackAndField;

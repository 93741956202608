const initialState = {
  party: false,
  isPartying: false,
  props: {},
};

const forms = (state = initialState, action) => {
  switch (action.type) {
    case 'CONFETTI_ON':
      return { ...state, party: true, props: action.props, isPartying: true };
    case 'CONFETTI_OFF':
      return { ...state, party: false };
    case 'CONFETTI_PARTY_IS_OVER':
      return { ...state, isPartying: false };
    default:
      return state;
  }
};

export default forms;

const initial = {};

const projectPhases = (state = initial, action) => {
  switch (action.type) {
    case 'PROJECT_PHASES_FETCH_SUCCESS': {
      const { projectId, projectPhases } = action;
      const updatedProjectPhases = { ...state[projectId], ...projectPhases };

      return { ...state, [projectId]: updatedProjectPhases };
    }

    case 'PROJECT_PHASE_DELETE_SUCCESS': {
      const { projectId, projectPhaseId } = action;
      const { [projectPhaseId]: deletedPhase, ...otherPhases } = state[projectId];

      return { ...state, [projectId]: otherPhases };
    }

    case 'PROJECT_PHASE_SAVE_SUCCESS': {
      const { projectId, projectPhase } = action;
      const updatedProjectPhases = { ...state[projectId], [projectPhase.id]: { ...projectPhase, dndId: `phase-${projectPhase.id}` } };

      return { ...state, [projectId]: updatedProjectPhases };
    }

    default:
      return state;
  }
};

export default projectPhases;

const initial = {
  taskActivity: {},
  projectTaskActivity: {},
};

const activityStream = (state = initial, action) => {
  switch (action.type) {
    case 'TASK_ACTIVITY_FETCH_SUCCESS': {
      const { activity, taskId } = action;
      const newActivity = { ...state.taskActivity, [taskId]: activity };

      return { ...state, taskActivity: newActivity };
    }

    case 'TASK_ACTIVITY_WEBSOCKET_NEW_EVENT': {
      const { taskId, event } = action;
      const { id } = event;
      const activity = { ...state.taskActivity[taskId], [id]: event };
      const newActivity = { ...state.taskActivity, [taskId]: activity };

      return { ...state, taskActivity: newActivity };
    }

    case 'TASK_ACTIVITY_WEBSOCKET_COMMENT_UPDATE': {
      const { taskId, event } = action;
      const { creation_event } = event.meta;
      const commentEvent = { ...state.taskActivity[taskId][creation_event.id] };
      const updatedComment = { ...commentEvent, child: event.child };
      const activity = { ...state.taskActivity[taskId], [creation_event.id]: updatedComment };
      const newActivity = { ...state.taskActivity, [taskId]: activity };

      return { ...state, taskActivity: newActivity };
    }

    case 'TASK_ACTIVITY_WEBSOCKET_COMMENT_DELETE': {
      const { taskId, event } = action;
      const { creation_event } = event.meta;
      const { [creation_event.id]: deletedComment, ...otherEvents } = state.taskActivity[taskId];
      const newActivity = { ...state.taskActivity, [taskId]: otherEvents };

      return { ...state, taskActivity: newActivity };
    }

    case 'TASK_ACTIVITY_COMMENT_ADD_HIGHLIGHT': {
      const { taskId, activityEventId } = action;
      const comment = { ...state.taskActivity[taskId][activityEventId].child };
      const updatedComment = { ...comment, highlighted: true };
      const updatedCommentEvent = { ...state.taskActivity[taskId][activityEventId],
        child: updatedComment };
      const activity = { ...state.taskActivity[taskId], [activityEventId]: updatedCommentEvent };
      const newActivity = { ...state.taskActivity, [taskId]: activity };

      return { ...state, taskActivity: newActivity };
    }

    case 'TASK_ACTIVITY_COMMENT_REMOVE_HIGHLIGHT': {
      const { taskId, activityEventId } = action;
      const comment = { ...state.taskActivity[taskId][activityEventId].child };
      const updatedComment = { ...comment, highlighted: false };
      const updatedCommentEvent = { ...state.taskActivity[taskId][activityEventId],
        child: updatedComment };
      const activity = { ...state.taskActivity[taskId], [activityEventId]: updatedCommentEvent };
      const newActivity = { ...state.taskActivity, [taskId]: activity };

      return { ...state, taskActivity: newActivity };
    }

    case 'PROJECT_TASK_ACTIVITY_FETCH_SUCCESS': {
      const { activity, projectId } = action;
      const currentActivity = state.projectTaskActivity[projectId] || [];
      const updatedProjectActivity = [...currentActivity, ...activity];
      const updatedActivity = { ...state.projectTaskActivity, [projectId]: updatedProjectActivity };

      return { ...state, projectTaskActivity: updatedActivity };
    }

    case 'PROJECT_TASK_ACTIVITY_CLEAR': {
      const { projectId } = action;
      const updatedActivity = { ...state.projectTaskActivity, [projectId]: [] };

      return { ...state, projectTaskActivity: updatedActivity };
    }

    default:
      return state;
  }
};

export default activityStream;

/* global config */
import React from 'react';
import PropTypes from 'prop-types';
import { datadogRum } from '@datadog/browser-rum';

import Error from 'components/Error/Error';
import ErrorPage from 'components/Error/ErrorPage';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    // Does not rely on setState since it is static.
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error,
      info,
    });

    datadogRum.addError(error);
  }

  render() {
    const { children, isPage = false, ...errorProps } = this.props;
    const { error, hasError, info } = this.state;

    if (hasError) {
      if (config.DEBUG) {
        return (
          <>
            <h1 id="error-boundary">Something went wrong...sorry.</h1>
            <div>
              <h2>The error: {error && error.toString()}</h2>
              <h2>Where it occured: </h2>
              <pre>
                {info && info.componentStack}
              </pre>
            </div>
          </>
        );
      }

      if (!isPage) {
        return (
          <Error
            error="Oops, something went wrong"
            {...errorProps}
          />
        );
      }

      return (
        <ErrorPage
          error="Oops, something went wrong"
          {...errorProps}
        />
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  isPage: PropTypes.bool,
  callback: PropTypes.func,
};

import type { BatonTheme } from 'theme';

const styles = (theme: BatonTheme) => {
  const { typography, palette, mixins } = theme;

  return {
    root: {
      ...typography.body2,
      ...mixins.linkifyText,
      color: palette.primary.main,
      fontFamily: typography.fontFamily,
      lineHeight: 'normal',
      '&:disabled': {
        color: palette.text.disabled,
      },
    },
    focus: {
      boxShadow: `0px 0px 2px 2px ${palette.primary.main}`,
      textDecoration: 'underline',
    },
    small: {
      ...typography.caption2,
    },
    negative: {
      color: palette.error.main,
    },
    neutral: {
      color: palette.text.primary,
    },
    positive: {
      color: palette.button.positive,
    },
  };
};

export default styles;

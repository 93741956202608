const initial = {};

const templatePhases = (state = initial, action) => {
  switch (action.type) {
    case 'TEMPLATE_PHASES_FETCH_SUCCESS': {
      const { templateId, templatePhases } = action;
      const updatedTemplatePhases = { ...state[templateId], ...templatePhases };

      return { ...state, [templateId]: updatedTemplatePhases };
    }

    case 'TEMPLATE_PHASE_DELETE_SUCCESS': {
      const { templateId, templatePhaseId } = action;
      const { [templatePhaseId]: deletedPhase, ...otherPhases } = state[templateId];

      return { ...state, [templateId]: otherPhases };
    }

    case 'TEMPLATE_PHASE_SAVE_SUCCESS': {
      const { templateId, templatePhase } = action;
      const updatedTemplatePhases = { ...state[templateId], [templatePhase.id]: { ...templatePhase, dndId: `phase-${templatePhase.id}` } };

      return { ...state, [templateId]: updatedTemplatePhases };
    }

    default:
      return state;
  }
};

export default templatePhases;

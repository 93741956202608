const initial = {};

const listViewColumns = (state = initial, action) => {
  switch (action.type) {
    case 'LIST_VIEW_COLUMNS_FETCH_SUCCESS': {
      return { ...state, ...action.listViewColumns };
    }

    case 'LIST_VIEW_COLUMNS_APPEND_SUCCESS': {
      const { listViewColumn } = action;

      return { ...state, [listViewColumn.id]: listViewColumn };
    }

    default:
      return state;
  }
};

export default listViewColumns;

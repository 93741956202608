import moment from 'moment';
import {
  TIMELINE_CUSTOMIZATION_TASK_TITLES,
  TIMELINE_CUSTOMIZATION_TASK_DEPENDENCIES,
  TIMELINE_CUSTOMIZATION_TASK_LATENESS,
  TIMELINE_CUSTOMIZATION_TASK_BASELINE,
  TIMELINE_CUSTOMIZATION_TASK_ACTUAL_DATES,
} from 'constants/timeline';

const persistedUI = JSON.parse(localStorage.getItem('ui'));
const persistedCollapsedOutlines = persistedUI?.projectTimelineView?.collapsedOutlines;
const hasCollapsedTimelineOutlines = persistedCollapsedOutlines
  && persistedCollapsedOutlines?.length;
const persistedCollapedItems = persistedUI?.projectTimelineView?.collapsed;
const hasCollapsedTimelineItems = persistedCollapedItems
  && Object.values(persistedCollapedItems)?.length;
const persistedTimelineViews = persistedUI?.projectTimelineView?.views;
const hasPersistedTimelineViews = persistedTimelineViews
&& Object.values(persistedTimelineViews)?.length;

const initialState = {
  collapsed: hasCollapsedTimelineItems ? persistedCollapedItems : {},
  collapsedOutlines: hasCollapsedTimelineOutlines ? persistedCollapsedOutlines : [],
  contentHeight: 0,
  highlightedTasks: [],
  isDraggingTasks: false,
  scrollTask: null,
  [TIMELINE_CUSTOMIZATION_TASK_DEPENDENCIES]: (
    persistedUI?.projectTimelineView?.[TIMELINE_CUSTOMIZATION_TASK_DEPENDENCIES] ?? true),
  [TIMELINE_CUSTOMIZATION_TASK_LATENESS]: (
    persistedUI?.projectTimelineView?.[TIMELINE_CUSTOMIZATION_TASK_LATENESS] || false),
  [TIMELINE_CUSTOMIZATION_TASK_TITLES]: (
    persistedUI?.projectTimelineView?.[TIMELINE_CUSTOMIZATION_TASK_TITLES] || false),
  [TIMELINE_CUSTOMIZATION_TASK_BASELINE]: (
    persistedUI?.projectTimelineView?.[TIMELINE_CUSTOMIZATION_TASK_BASELINE] || true),
  [TIMELINE_CUSTOMIZATION_TASK_ACTUAL_DATES]: (
    persistedUI?.projectTimelineView?.[TIMELINE_CUSTOMIZATION_TASK_ACTUAL_DATES] || true),
  tasksYCoordinates: {},
  views: hasPersistedTimelineViews ? persistedTimelineViews : {},
  width: 0,
  templatePreviewField: { start_datetime: moment().startOf('day') },
};

// eslint-disable-next-line default-param-last
const projectTimelineView = (state = initialState, action) => {
  switch (action.type) {
    case 'PROJECT_TIMELINE_SET_VIEWS': {
      const { projectId, viewId } = action;
      const updatedViews = { ...state.views, [projectId]: viewId };

      return { ...state, views: updatedViews };
    }

    case 'PROJECT_TIMELINE_SET_OUTLINE_EXPAND':
      return { ...state, isOutlineExpanded: action.isOpen };

    case 'PROJECT_TIMELINE_SET_TASK_Y_COORDINATES': {
      const { taskYObject } = action;
      const { id, y } = taskYObject;
      const updatedCoordinates = { ...state.tasksYCoordinates, [id]: y };

      return { ...state, tasksYCoordinates: updatedCoordinates };
    }

    case 'PROJECT_TIMELINE_DELETE_TASK_Y_COORDINATES': {
      const { taskId } = action;
      const { [taskId]: deletedCoordinates, ...updatedCoordinates } = state.tasksYCoordinates;

      return { ...state, tasksYCoordinates: updatedCoordinates };
    }

    case 'PROJECT_TIMELINE_RESET_TASKS_Y_COORDINATES':
      return { ...state, tasksYCoordinates: {} };

    case 'PROJECT_TIMELINE_SET_HIGHLIGHTED_TASKS':
      return { ...state, highlightedTasks: action.tasks };

    case 'PROJECT_TIMELINE_TASK_CUSTOMIZATION_TOGGLE': {
      const currentValue = state[action.taskCustomizationKey];

      return { ...state, [action.taskCustomizationKey]: !currentValue };
    }

    case 'PROJECT_TIMELINE_IS_DRAGGING_TASKS_SET':
      return { ...state, isDraggingTasks: action.isDraggingTasks };

    case 'PROJECT_TIMELINE_EXPAND_ALL_TIMELINE_ITEMS': {
      const { projectId } = action;
      const { [projectId]: expandedItems, ...updatedCollapsed } = state.collapsed;

      return { ...state, collapsed: updatedCollapsed };
    }

    case 'PROJECT_TIMELINE_SET_COLLAPSED_PHASES': {
      const { projectId, phases } = action;
      const updatedProject = { ...state.collapsed[projectId], phases };
      const updatedCollapsed = { ...state.collapsed, [projectId]: updatedProject };

      return { ...state, collapsed: updatedCollapsed };
    }

    case 'PROJECT_TIMELINE_SET_COLLAPSED_MILESTONES': {
      const { projectId, milestones } = action;
      const updatedProject = { ...state.collapsed[projectId], milestones };
      const updatedCollapsed = { ...state.collapsed, [projectId]: updatedProject };

      return { ...state, collapsed: updatedCollapsed };
    }

    case 'PROJECT_TIMELINE_SET_COLLAPSED_TASKS': {
      const { projectId, tasks } = action;
      const updatedProject = { ...state.collapsed[projectId], tasks };
      const updatedCollapsed = { ...state.collapsed, [projectId]: updatedProject };

      return { ...state, collapsed: updatedCollapsed };
    }

    case 'PROJECT_TIMELINE_SET_COLLAPSED_OUTLINES': {
      return { ...state, collapsedOutlines: action.outlines };
    }

    case 'PROJECT_TIMELINE_SET_SCROLL_TASK': {
      return { ...state, scrollTask: action.scrollTask };
    }

    case 'PROJECT_TIMELINE_SET_WIDTH': {
      return { ...state, width: action.width };
    }

    case 'PROJECT_TIMELINE_SET_CONTENT_HEIGHT': {
      return { ...state, contentHeight: action.contentHeight };
    }

    case 'PROJECT_TIMELINE_SET_TEMPLATE_PREVIEW_FIELD': {
      return { ...state, templatePreviewField: action.templatePreviewField };
    }

    default:
      return state;
  }
};

export default projectTimelineView;

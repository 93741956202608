const initial = {
  templateTasks: {},
  templateTaskUpdates: [],
};

// eslint-disable-next-line default-param-last
const templateTasks = (state = initial, action) => {
  switch (action.type) {
    case 'TEMPLATE_TASKS_FETCH_SUCCESS': {
      const { templateId } = action;
      const newTasks = { ...state.templateTasks, [templateId]: action.tasks };

      return { ...state, templateTasks: newTasks };
    }

    case 'TEMPLATE_TASKS_SAVE_SUCCESS': {
      const { templateId, tasks } = action;

      const projTasks = { ...state.templateTasks[templateId], ...tasks };
      const savedTasks = { ...state.templateTasks, [templateId]: projTasks };

      return { ...state, templateTasks: savedTasks };
    }

    case 'TEMPLATE_TASK_FETCH_SUCCESS':
    case 'TEMPLATE_TASK_SAVE_SUCCESS': {
      const { templateId, task } = action;
      const projTasks = { ...state.templateTasks[templateId], [action.task.id]: task };
      const savedTasks = { ...state.templateTasks, [templateId]: projTasks };

      return { ...state, templateTasks: savedTasks };
    }

    case 'TEMPLATE_TASKS_COPY_SUCCESS': {
      const { templateId, copiedTasks } = action;
      const templTasks = { ...state.templateTasks[templateId], ...copiedTasks };
      const savedTasks = { ...state.templateTasks, [templateId]: templTasks };

      return { ...state, templateTasks: savedTasks };
    }

    case 'TEMPLATE_TASK_DELETE_SUCCESS': {
      const { templateId, taskId } = action;
      const { [taskId]: deletedTask, ...otherTasks } = state.templateTasks[templateId];
      const savedTasks = { ...state.templateTasks, [templateId]: otherTasks };

      return { ...state, templateTasks: savedTasks };
    }

    case 'TEMPLATE_TASK_LINK_SAVE_SUCCESS': {
      const { templateId, taskId, link } = action;
      const task = state.templateTasks[templateId][taskId];
      const links = { ...task.links, [link.id]: link };
      const newTask = { ...task, links };
      const templateTasks = { ...state.templateTasks[templateId], [taskId]: newTask };
      const allTasks = { ...state.templateTasks, [templateId]: templateTasks };

      return { ...state, templateTasks: allTasks };
    }

    case 'TEMPLATE_TASK_LINK_DELETE_SUCCESS': {
      const { templateId, taskId, linkId } = action;
      const task = state.templateTasks[templateId][taskId];
      const { [linkId]: deletedLink, ...otherLinks } = task.links;
      const newTask = { ...task, links: otherLinks };
      const templateTasks = { ...state.templateTasks[templateId], [taskId]: newTask };
      const allTasks = { ...state.templateTasks, [templateId]: templateTasks };

      return { ...state, templateTasks: allTasks };
    }

    case 'TEMPLATE_TASK_LINK_REPLACE_SUCCESS': {
      const { templateId, taskId, previousLinkId, link } = action;
      const task = state.templateTasks[templateId][taskId];
      const { [previousLinkId]: replacedTask, ...otherLinks } = task.links;
      const updatedLinks = { ...otherLinks, [link.id]: link };
      const updatedTask = { ...task, links: updatedLinks };
      const projTasks = { ...state.templateTasks[templateId], [taskId]: updatedTask };
      const savedTasks = { ...state.templateTasks, [templateId]: projTasks };

      return { ...state, templateTasks: savedTasks };
    }

    case 'TEMPLATE_TASK_CHECKLIST_UPDATE': {
      const { templateId, taskId, checklist_items } = action;
      const task = state.templateTasks[templateId][taskId];
      const newTask = { ...task, checklist_items };
      const templateTasks = { ...state.templateTasks[templateId], [taskId]: newTask };
      const allTasks = { ...state.templateTasks, [templateId]: templateTasks };

      return { ...state, templateTasks: allTasks };
    }

    case 'SET_TEMPLATE_TASK_UPDATE': {
      const { taskId, updateId } = action;
      const { templateTaskUpdates } = state;
      const updatedTaskUpdates = templateTaskUpdates.concat([{ taskId, updateId }]);

      return { ...state, templateTaskUpdates: updatedTaskUpdates };
    }

    case 'REMOVE_TEMPLATE_TASK_UPDATE': {
      const { updateId } = action;
      const { templateTaskUpdates } = state;
      const updatedTaskUpdates = templateTaskUpdates
        .filter(update => update?.updateId !== updateId);

      return { ...state, templateTaskUpdates: updatedTaskUpdates };
    }

    default:
      return state;
  }
};

export default templateTasks;

export default {
  htmlFontSize: 10,
  fontFamily: [
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
  fontWeightBold: 700,
  link: {
    color: '#000000de',
    textDecoration: 'none',
  },
  display1: {
    fontSize: '6.4rem',
    fontWeight: 500,
    letterSpacing: '-0.01em',
    lineHeight: '1.175',
  },
  display2: {
    fontSize: '4.8rem',
    fontWeight: 500,
    letterSpacing: '-0.01em',
    lineHeight: '1.175',
  },
  display3: {
    fontSize: '4.8rem',
    fontWeight: 300,
    letterSpacing: '-0.01em',
    lineHeight: '1.175',
  },
  display4: {
    fontSize: '3.2rem',
    fontWeight: 300,
    letterSpacing: '-0.01em',
    lineHeight: '1.175',
  },
  display5: {
    fontSize: '3.2rem',
    fontWeight: 500,
    letterSpacing: '-0.01em',
    lineHeight: '1.175',
  },
  h1: {
    fontSize: '2.4rem',
    fontWeight: 400,
    letterSpacing: '-0.01em',
    lineHeight: '1.175',
  },
  h2: {
    fontSize: '2.4rem',
    fontWeight: 500,
    letterSpacing: '-0.01em',
    lineHeight: '1.175',
  },
  h3: {
    fontSize: '1.7rem',
    fontWeight: 400,
    letterSpacing: '0.015em',
    lineHeight: '1.175',
  },
  h4: {
    fontSize: '1.7rem',
    fontWeight: 500,
    letterSpacing: '0.015em',
    lineHeight: '1.175',
  },
  paragraph: {
    fontSize: '1.6rem',
    fontWeight: 400,
    letterSpacing: 'normal',
    lineHeight: '1.25',
  },
  body1: {
    fontSize: '1.4rem',
    fontWeight: 400,
    letterSpacing: 'normal',
    lineHeight: 1.25,
  },
  body2: {
    fontSize: '1.4rem',
    fontWeight: 500,
    letterSpacing: 'normal',
    lineHeight: 1.25,
  },
  body3: {
    fontSize: '1.4rem',
    fontWeight: 700,
    letterSpacing: 'normal',
    lineHeight: 1.25,
  },
  caption1: {
    fontSize: '1.2rem',
    fontWeight: 400,
    letterSpacing: '0.015em',
    lineHeight: '1.2',
  },
  caption2: {
    fontSize: '1.2rem',
    fontWeight: 500,
    letterSpacing: '0.015em',
    lineHeight: '1.2',
  },
  overline1: {
    fontSize: '1.1rem',
    fontWeight: 500,
    letterSpacing: '0.02em',
    lineHeight: 'normal', // adding this for now to avoid weird centering issues
    textTransform: 'uppercase',
  },
  overline2: {
    fontSize: '1.1rem',
    fontWeight: 500,
    letterSpacing: '0.015em',
    lineHeight: '1.28',
  },
};

export const TYPOGRAPHY_VARIANTS = [
  'body1',
  'body2',
  'body3',
  'caption1',
  'caption2',
  'display1',
  'display2',
  'display3',
  'display4',
  'display5',
  'h1',
  'h2',
  'h3',
  'h4',
  'overline1',
  'overline2',
];

import { combineReducers } from 'redux';

import activityStream from './activityStream';
import companies from './companies';
import customFields from './customFields';
import dashboardCards from './dashboardCards';
import deploy from './deploy';
import jiraProjects from './jiraProjects';
import listViewColumns from './listViewColumns';
import milestones from './milestones';
import phases from './phases';
import projectFilters from './projectFilters';
import projectHealths from './projectHealths';
import projectHolidays from './projectHolidays';
import projectPhases from './projectPhases';
import projectRates from './projectRates';
import projectSettings from './projectSettings';
import projectDashboardCards from './projectDashboardCards';
import projects from './projects';
import taskLastViews from './taskLastViews';
import tasks from './tasks';
import templateMilestones from './templateMilestones';
import templatePhases from './templatePhases';
import templateSettings from './templateSettings';
import templateTasks from './templateTasks';
import templates from './templates';
import ui from './ui';
import user from './user';
import users from './users';
import webSockets from './webSockets';
import workspace from './workspace';

export default () => combineReducers({
  activityStream,
  companies,
  customFields,
  dashboardCards,
  deploy,
  jiraProjects,
  listViewColumns,
  milestones,
  phases,
  projectFilters,
  projectHealths,
  projectHolidays,
  projectPhases,
  projectRates,
  projectSettings,
  projectDashboardCards,
  projects,
  taskLastViews,
  tasks,
  templateMilestones,
  templatePhases,
  templateSettings,
  templateTasks,
  templates,
  ui,
  user,
  users,
  webSockets,
  workspace,
});

const initial = {
  taskLastViews: {},
};

const taskLastViews = (state = initial, action) => {
  switch (action.type) {
    case 'TASK_LAST_VIEW_UPDATE_SUCCESS': {
      const { projectId, taskLastView } = action;
      const current = state.taskLastViews[projectId];
      const updatedViews = { ...current, ...taskLastView };

      return { ...state, taskLastViews: { ...state.taskLastViews, [projectId]: updatedViews } };
    }

    case 'TASK_LAST_VIEWS_FETCH_SUCCESS': {
      const { projectId, taskLastViews } = action;
      const newTaskLastViews = { ...state.taskLastViews, [projectId]: taskLastViews };

      return { ...state, taskLastViews: newTaskLastViews };
    }

    default:
      return state;
  }
};

export default taskLastViews;

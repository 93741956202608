const initial = {
  projects: {},
};

// eslint-disable-next-line default-param-last
const projects = (state = initial, action) => {
  switch (action.type) {
    case 'PROJECT_FETCH_SUCCESS': {
      const { project } = action;

      return { ...state, projects: { ...state.projects, [project.id]: project } };
    }

    case 'PROJECT_DELETE_SUCCESS': {
      const { projectId } = action;
      const { [projectId]: deletedProject, ...otherProjects } = state.projects;

      return { ...state, projects: otherProjects };
    }

    case 'PROJECT_USER_CREATE_SUCCESS': {
      const { projectId, projectUser } = action;
      const project = state.projects[projectId];
      const projectUsers = project.users;
      const updatedUsers = { ...projectUsers, [projectUser.id]: projectUser };
      const updatedProject = { ...project, users: updatedUsers };

      return { ...state, projects: { ...state.projects, [projectId]: updatedProject } };
    }

    case 'PROJECT_USER_DELETE_SUCCESS': {
      const { projectId, projectUserId } = action;
      const project = state.projects[projectId];
      const { [projectUserId]: deletedUser, ...otherUsers } = project.users;
      const updatedProject = { ...project, users: otherUsers };

      return { ...state, projects: { ...state.projects, [projectId]: updatedProject } };
    }

    case 'PROJECT_LINK_ADD_SUCCESS': {
      const { projectId, link } = action;
      const project = state.projects[projectId];
      const projectLinks = project.links;
      const updatedLinks = { ...projectLinks, [link.id]: link };
      const updatedProject = { ...project, links: updatedLinks };

      return { ...state, projects: { ...state.projects, [projectId]: updatedProject } };
    }

    case 'PROJECT_LINK_DELETE_SUCCESS': {
      const { projectId, linkId } = action;
      const project = state.projects[projectId];
      const { [linkId]: deletedLink, ...otherLinks } = project.links;
      const updatedProject = { ...project, links: otherLinks };

      return { ...state, projects: { ...state.projects, [projectId]: updatedProject } };
    }

    case 'PROJECT_LINK_REPLACE_SUCCESS': {
      const { projectId, previousLinkId, link } = action;
      const project = state.projects[projectId];
      const { [previousLinkId]: previousLink, ...otherLinks } = project.links;
      const updatedLinks = { ...otherLinks, [link.id]: link };
      const updatedProject = { ...project, links: updatedLinks };

      return { ...state, projects: { ...state.projects, [projectId]: updatedProject } };
    }

    default:
      return state;
  }
};

export default projects;

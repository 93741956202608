/* eslint-disable default-param-last */
const initial = {};

const projectFilters = (state = initial, action) => {
  switch (action.type) {
    case 'PROJECT_FILTERS_FETCH_SUCCESS': {
      const filters = Object.values(action.projectFilters).reduce((previous, current) => ({
        ...previous,
        [current.id]: current,
      }), {});

      return { ...state, ...filters };
    }

    case 'PROJECT_FILTER_APPEND_SUCCESS': {
      const { projectFilter } = action;

      return { ...state, [projectFilter.id]: projectFilter };
    }

    case 'PROJECT_FILTER_DELETE_SUCCESS': {
      const { [action.projectFilterId]: deletedProjectFilter, ...otherProjectFilters } = state;

      return otherProjectFilters;
    }

    default:
      return state;
  }
};

export default projectFilters;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import Button from 'components/Buttons/Button';
import Typography from 'components/ui/Atoms/Typography';
import TrackAndField from 'components/Illustrations/TrackAndField';
import styles from './ErrorPageStyles';

const useStyles = makeStyles({ name: 'ErrorPage' })(styles);

function ErrorPage(props) {
  const {
    recoveryUrl,
    recoveryText = 'Go to Dashboard',
    message = 'We can\'t seem to fetch what you\'re looking for.',
    error = '404',
    onFeedback,
  } = props;
  const { classes } = useStyles();

  return (
    <section className={classes.root} data-cy="error-page" data-error={error}>
      <TrackAndField color="#FFF" className={classes.background} />
      <div className={classes.content}>
        <Typography
          className={classes.title}
          component="h1"
          variant="display2"
          data-cy="error-page-title"
        >
          {error}
        </Typography>
        <Typography className={classes.text} component="p" variant="display3">
          {message}
        </Typography>
        <Button
          href={recoveryUrl}
          data-cy="error-page-button"
          className={classes.button}
        >
          {recoveryText}
        </Button>
        {onFeedback && (
        <Button
          onClick={onFeedback}
          variant="outline"
          className={classes.button}
        >
          Report Feedback
        </Button>
        )}
      </div>
      <img
        className={classes.image}
        src="/static/images/prince.gif"
        alt="Little prince can't fetch"
      />
    </section>
  );
}

ErrorPage.propTypes = {
  recoveryUrl: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  recoveryText: PropTypes.string,
  message: PropTypes.string,
  onFeedback: PropTypes.func,
};

export default ErrorPage;

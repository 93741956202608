/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { forwardRef } from 'react';
import { ButtonBase } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import type { ButtonBaseProps } from '@mui/material';
import type { FC } from 'react';
import type IconProps from 'components/ui/Atoms/Icons/iconTypes';

import { BUTTON_COLOR_SCHEMES, BUTTON_VARIANTS } from './buttonConstants';

import styles from './ButtonStyles';

const useStyles = makeStyles({ name: 'Button' })(styles);

export type ButtonProps = ButtonBaseProps & {
  Icon?: FC
  colorScheme?: typeof BUTTON_COLOR_SCHEMES[number]
  iconPosition?: 'right' | 'left'
  iconProps?: IconProps
  size?: 'small' | 'medium'
  variant?: typeof BUTTON_VARIANTS[number]
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    className,
    colorScheme = 'brand',
    Icon,
    iconPosition = 'left',
    iconProps = {},
    variant = 'solid',
    size = 'medium',
    ...otherProps
  } = props;
  const hasChildren = Boolean(children);
  const { classes, cx } = useStyles();

  return (
    <ButtonBase
      className={cx(
        classes.button,
        classes[colorScheme],
        classes[variant],
        classes[size],
        { [classes.withRightIcon]: iconPosition === 'right' },
        { [classes.withIconOnly]: !hasChildren },
        className,
      )}
      classes={{ focusVisible: classes.focus }}
      ref={ref}
      {...otherProps}
    >
      {Icon && <Icon className={classes.icon} strokeWidth={2} {...iconProps} />}
      {children && <span className={classes.label}>{children}</span>}
    </ButtonBase>
  );
});

Button.displayName = 'Button';

export default Button;

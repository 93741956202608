const initial = {
  companies: {},
};

const companies = (state = initial, action) => {
  switch (action.type) {
    case 'COMPANIES_FETCH_SUCCESS':
      return { ...state, companies: action.companies };

    // So far unused
    case 'COMPANY_FETCH_SUCCESS': {
      const { company } = action;
      return { ...state, companies: { ...state.companies, [company.id]: company } };
    }

    case 'COMPANIES_CREATE_SUCCESS': {
      const { company } = action;
      const newCompanies = { ...state.companies, [company.id]: company };
      return { ...state, companies: newCompanies };
    }

    default:
      return state;
  }
};

export default companies;

const initial = {};

const user = (state = initial, action) => {
  switch (action.type) {
    case 'PHASES_FETCH_SUCCESS': {
      return { ...action.phases };
    }

    case 'PHASE_UPDATE_SUCCESS': {
      const { phase } = action;

      return { ...state, [phase.id]: phase };
    }

    case 'PHASE_DELETE_SUCCESS': {
      const { [action.phaseId]: deletedPhase, ...otherPhases } = state;

      return { ...otherPhases };
    }

    default:
      return state;
  }
};

export default user;

const styles = (theme) => {
  const { baseUnit, mixins, palette } = theme;
  const { position } = mixins;

  return {
    root: {
      ...position('absolute', '50%', '50%', 'auto', 'auto'),
      transform: 'translate(50%, -50%)',
      textAlign: 'center',
    },
    icon: {
      marginBottom: baseUnit * 4,
    },
    error: {
      marginBottom: baseUnit * 14,
      fontWeight: 300,
      letterSpacing: 0,
    },
    link: {
      marginBottom: baseUnit * 2,
    },
    feedbackLink: {
      display: 'block',
      color: palette.icons.gray,
      margin: '0 auto',
    },
  };
};

export default styles;

const initial = {};

const user = (state = initial, action) => {
  switch (action.type) {
    case 'USER_UPDATE_SUCCESS':
      return { ...action.data };

    case 'VISUAL_CUE_CREATE_SUCCESS': {
      const { visualCue } = action;
      // @SEE: Ensure consistent keys. baton.utils.py -  keyize_visual_cues.
      const newVisualCues = { ...state.visual_cues, [visualCue.visual_cue_id]: visualCue };

      return { ...state, visual_cues: newVisualCues };
    }

    case 'TURN_ORIENTATION_OFF': {
      return { ...state, show_orientation: false };
    }

    case 'TURN_ORIENTATION_ON': {
      return { ...state, show_orientation: true };
    }

    case 'WORKSPACE_COMPANY_UPDATE_SUCCESS': {
      const { vendor } = action;
      const updatedWorkspace = { ...state.workspace, vendor };

      return { ...state, workspace: updatedWorkspace };
    }

    case 'WORKSPACE_ATLASSIAN_TENANT_UPDATE_SUCCESS': {
      const { atlassian_tenant } = action;
      const updatedWorkspace = { ...state.workspace, atlassian_tenant };

      return { ...state, workspace: updatedWorkspace };
    }

    default:
      return state;
  }
};

export default user;

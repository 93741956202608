const initial = {
  templateMilestones: {},
};

const templateMilestones = (state = initial, action) => {
  switch (action.type) {
    case 'TEMPLATE_MILESTONES_FETCH_SUCCESS': {
      const newTemplateMilestones = { ...state.templateMilestones, [action.templateId]: action.milestones };
      return { ...state, templateMilestones: newTemplateMilestones };
    }

    case 'TEMPLATE_MILESTONES_DELETE_SUCCESS': {
      const { templateId, milestoneId } = action;
      const { [templateId]: templateMilestones, ...otherTemplatesMilestones } = state.templateMilestones;
      const { [milestoneId]: deletedMilestone, ...remainingMilestones } = templateMilestones;

      return {
        ...state,
        templateMilestones: {
          ...otherTemplatesMilestones,
          [templateId]: remainingMilestones,
        },
      };
    }

    case 'TEMPLATE_MILESTONE_SAVE_SUCCESS': {
      const templateMilestones = { ...state.templateMilestones[action.templateId], [action.milestone.id]: action.milestone };
      const newMilestones = { ...state.templateMilestones, [action.templateId]: templateMilestones };
      return { ...state, templateMilestones: newMilestones };
    }

    default:
      return state;
  }
};

export default templateMilestones;

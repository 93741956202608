const initial = {};

const customFields = (state = initial, action) => {
  switch (action.type) {
    case 'CUSTOM_FIELDS_FETCH_SUCCESS': {
      return { ...state, ...action.customFields };
    }

    case 'CUSTOM_FIELD_APPEND_SUCCESS': {
      const { customField } = action;

      return { ...state, [customField.id]: customField };
    }

    case 'CUSTOM_FIELD_DELETE_SUCCESS': {
      const { [action.customFieldId]: deletedField, ...otherFields } = state;

      return otherFields;
    }

    default:
      return state;
  }
};

export default customFields;

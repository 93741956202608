const initial = {
  workspace: {},
};

// eslint-disable-next-line default-param-last
const workspace = (state = initial, action) => {
  switch (action.type) {
    case 'WORKSPACE_SETTINGS_FETCH_SUCCESS':
    case 'WORKSPACE_SETTINGS_UPDATE_SUCCESS':
      return { ...state, settings: action.settings };

    case 'WORKSPACE_DEPARTMENTS_FETCH_SUCCESS':
      return { ...state, departments: action.departments };

    case 'WORKSPACE_DEPARTMENT_CREATE_SUCCESS': {
      const { department } = action;

      return { ...state, departments: { ...state.departments, [department.id]: department } };
    }

    case 'WORKSPACE_DEPARTMENT_UPDATE_SUCCESS': {
      const { department } = action;
      const { departments: oldDepartments } = state;
      const newDepartments = { ...oldDepartments, [department.id]: department };

      return { ...state, departments: newDepartments };
    }

    case 'WORKSPACE_DEPARTMENT_DELETE_SUCCESS': {
      const { departmentId } = action;
      const { [departmentId]: deletedDepartment, ...otherDepartments } = state.departments;

      return { ...state, departments: otherDepartments };
    }

    case 'WORKSPACE_WORKDAYS_FETCH_SUCCESS':
      return { ...state, workdays: action.workdays };

    case 'WORKSPACE_HOLIDAY_UPDATE_SUCCESS': {
      const { holiday } = action;
      const { holidays: oldHolidays } = state;
      const newHolidays = { ...oldHolidays, [holiday.id]: holiday };

      return { ...state, holidays: newHolidays };
    }

    case 'WORKSPACE_HOLIDAYS_FETCH_SUCCESS':
      return { ...state, holidays: action.holidays };

    default:
      return state;
  }
};

export default workspace;

/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable default-param-last */
import type { Action } from 'redux';
import type { TimeEntryNode } from 'graphql/__generated__/graphql';

export type TimeTrackedProps = {
  projectId?: number
  referenceDate?: Date
  taskId?: number
  timeEntry?: TimeEntryNode
  userId?: number
};

type State = {
  open: boolean
  props: TimeTrackedProps
};

const initialProps = {
  projectId: null,
  referenceDate: null,
  taskId: null,
  timeEntry: undefined,
  userId: null,
};

const initialState: State = {
  open: false,
  props: initialProps,
};

/**
 * Handles the state for the time tracked formed located in MainLayout.
 */
const timeTrackedForm = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case 'TIME_TRACKED_FORM_OPEN': {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const updatedProps: TimeTrackedProps = { ...state.props, ...action.props };

      return { ...state, open: true, props: updatedProps };
    }
    case 'TIME_TRACKED_FORM_CLOSE':
      return { ...state, open: false, props: initialProps };
    default:
      return state;
  }
};

export default timeTrackedForm;

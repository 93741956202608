const initial = {};

const projectHealths = (state = initial, action) => {
  switch (action.type) {
    case 'PROJECT_HEALTHS_FETCH_SUCCESS': {
      const { projectId, healths } = action;
      return { ...state, [projectId]: healths };
    }

    case 'PROJECT_HEALTH_UPDATE_SUCCESS': {
      const { projectId, health } = action;
      const updatedProjectHealths = { ...state[projectId], [health.id]: health };

      return { ...state, [projectId]: updatedProjectHealths };
    }

    default:
      return state;
  }
};

export default projectHealths;

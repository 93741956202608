const initial = {};

const projectSettings = (state = initial, action) => {
  switch (action.type) {
    case 'PROJECT_SETTINGS_FETCH_SUCCESS': {
      const { projectId, projectSettings } = action;

      return { ...state, [projectId]: projectSettings };
    }

    case 'PROJECT_SETTING_UPDATE_SUCCESS': {
      const { projectId, setting } = action;
      const updatedProjectSettings = { ...state[projectId], [setting.id]: setting };

      return { ...state, [projectId]: updatedProjectSettings };
    }

    default:
      return state;
  }
};

export default projectSettings;

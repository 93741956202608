const initial = {};

const dashboardCards = (state = initial, action) => {
  switch (action.type) {
    case 'DASHBOARD_CARDS_FETCH_SUCCESS': {
      return { ...state, ...action.dashboardCards };
    }

    case 'DASHBOARD_CARD_UPDATE_SUCCESS': {
      const { dashboardCard } = action;

      return { ...state, [dashboardCard.key]: dashboardCard };
    }

    default:
      return state;
  }
};

export default dashboardCards;

const initial = {
  authActive: { on: false },
  authLogOut: { on: false },
  phasesUpdate: { on: false },
  projectsUpdate: { on: false },
  companiesUpdate: { on: false },
  workspaceSettingsUpdate: { on: false },
  timeEntries: { on: false },
  taskActivityUpdates: {},
  projectUpdates: {},
  templateUpdates: {},
  listViewColumnsUpdate: { on: false },
};

// eslint-disable-next-line default-param-last
const webSockets = (state = initial, action) => {
  switch (action.type) {
    case 'WEBSOCKET_AUTH_ACTIVE_ON':
      return { ...state, authActive: { on: true, webSocket: action.webSocket } };

    case 'WEBSOCKET_AUTH_ACTIVE_OFF':
      return { ...state, authActive: { on: false } };

    case 'WEBSOCKET_AUTH_LOGOUT_ON':
      return { ...state, authLogOut: { on: true, webSocket: action.webSocket } };

    case 'WEBSOCKET_AUTH_LOGOUT_OFF':
      return { ...state, authLogOut: { on: false } };

    case 'WEBSOCKET_TASK_ACTIVITY_UPDATE_ON': {
      const { taskId, webSocket } = action;
      const taskActivityUpdates = { ...state.taskActivityUpdates, [taskId]: webSocket };

      return { ...state, taskActivityUpdates };
    }

    case 'WEBSOCKET_TASK_ACTIVITY_UPDATE_OFF': {
      const { taskId } = action;
      const { [taskId]: removedUpdate, ...taskActivityUpdates } = state.taskActivityUpdates;

      return { ...state, taskActivityUpdates };
    }

    case 'WEBSOCKET_PHASES_UPDATE_ON':
      return { ...state, phasesUpdate: { on: true, webSocket: action.webSocket } };

    case 'WEBSOCKET_PHASES_UPDATE_OFF':
      return { ...state, phasesUpdate: { on: false } };

    case 'WEBSOCKET_LIST_VIEW_COLUMNS_ON':
      return { ...state, listViewColumnsUpdate: { on: true, webSocket: action.webSocket } };

    case 'WEBSOCKET_LIST_VIEW_COLUMNS_OFF':
      return { ...state, listViewColumnsUpdate: { on: false } };

    case 'WEBSOCKET_PROJECTS_UPDATE_ON':
      return { ...state, projectsUpdate: { on: true, webSocket: action.webSocket } };

    case 'WEBSOCKET_PROJECTS_UPDATE_OFF':
      return { ...state, projectsUpdate: { on: false } };

    case 'WEBSOCKET_COMPANIES_UPDATE_ON':
      return { ...state, companiesUpdate: { on: true, webSocket: action.webSocket } };

    case 'WEBSOCKET_COMPANIES_UPDATE_OFF':
      return { ...state, companiesUpdate: { on: false } };

    case 'WEBSOCKET_PROJECT_UPDATE_ADD': {
      const { projectId, webSocket } = action;
      const projectUpdates = { ...state.projectUpdates, [projectId]: webSocket };

      return { ...state, projectUpdates };
    }

    case 'WEBSOCKET_PROJECT_UPDATE_REMOVE': {
      const { projectId } = action;
      const { [projectId]: removedUpdate, ...projectUpdates } = state.projectUpdates;

      return { ...state, projectUpdates };
    }

    case 'WEBSOCKET_TEMPLATE_UPDATE_ADD': {
      const { templateId, webSocket } = action;
      const templateUpdates = { ...state.templateUpdates, [templateId]: webSocket };

      return { ...state, templateUpdates };
    }

    case 'WEBSOCKET_TEMPLATE_UPDATE_REMOVE': {
      const { templateId } = action;
      const { [templateId]: removedUpdate, ...templateUpdates } = state.templateUpdates;

      return { ...state, templateUpdates };
    }

    default:
      return state;
  }
};

export default webSockets;

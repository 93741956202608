const initialState = {
  searchModalOpen: false,
  showDisableOverlay: false,
  snackbar: {
    open: false,
  },
  lastEntryPath: null,
};

// eslint-disable-next-line default-param-last
const global = (state = initialState, action) => {
  switch (action.type) {
    case 'UI_SET_DISABLE_OVERLAY':
      return { ...state, showDisableOverlay: action.show };
    case 'SET_SNACKBAR': {
      const { snackbar } = action;

      return { ...state, snackbar };
    }
    case 'SET_LAST_ENTRY_PATH': {
      const { path } = action;

      return { ...state, lastEntryPath: path };
    }
    case 'OPEN_SEARCH_MODAL': {
      return { ...state, searchModalOpen: true };
    }
    case 'CLOSE_SEARCH_MODAL': {
      return { ...state, searchModalOpen: false };
    }
    default:
      return state;
  }
};

export default global;

const styles = (theme) => {
  const { baseUnit, breakpoints, mixins, palette, typography } = theme;
  const { position } = mixins;

  return {
    root: {
      background: palette.background.secondary,
      alignItems: 'center',
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      width: '100vw',
      padding: baseUnit * 5,
    },
    content: {
      maxWidth: 600,
      marginRight: '10%',
      zIndex: 1,
    },
    background: {
      ...position('absolute', 0),
      paddingTop: 210,
      maxWidth: '100%',
      maxHeight: '100%',
    },
    text: {
      marginBottom: baseUnit * 14,
      [breakpoints.down('md')]: {
        ...typography.h1,
        marginBottom: baseUnit * 8,
      },
    },
    button: {
      margin: `0px ${baseUnit * 2}px ${baseUnit * 2}px 0px`,
    },
    image: {
      maxWidth: 504,
      width: '45%',
      zIndex: 1,
    },
  };
};

export default styles;

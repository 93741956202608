import { combineReducers } from 'redux';

import confetti from './confetti';
import contentDrawer from './contentDrawer';
import global from './global';
import projectView from './projectView';
import projectsView from './projectsView';
import projectTimelineOutlineColumns from './projectTimelineOutlineColumns';
import projectTimelineView from './projectTimelineView';
import startingLineView from './startingLineView';
import timeTrackedForm from './timeTrackedForm';
import resourcesView from './resourcesView';

export default combineReducers({
  contentDrawer,
  confetti,
  global,
  projectView,
  projectsView,
  projectTimelineOutlineColumns,
  projectTimelineView,
  startingLineView,
  timeTrackedForm,
  resourcesView,
});

/* global data */
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { getWorkspaceRedirectBaseUrl, isWorkspace404 } from 'helpers/workspaces';
import theme from 'theme';
import ErrorPage from './ErrorPage';

function ErrorPageContainer(props) {
  const hasWorkspace = data && 'workspace' in data;
  const isWorkspaceError = hasWorkspace && isWorkspace404(data.workspace);

  return (
    <StyledEngineProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <ErrorPage
            recoveryUrl={isWorkspaceError ? getWorkspaceRedirectBaseUrl(data.workspace) : '/app/projects'}
            recoveryText={isWorkspaceError ? 'Find workspace' : 'Go to Dashboard'}
            message={isWorkspaceError ? 'We can\'t seem to fetch that workspace.' : 'We can\'t seem to fetch what you’re looking for.'}
            {...props}
          />
        </CssBaseline>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default ErrorPageContainer;

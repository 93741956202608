const initial = {
  jiraProjects: {},
};

const jiraProjects = (state = initial, action) => {
  switch (action.type) {
    case 'JIRA_PROJECT_FETCH_SUCCESS': {
      const { projectId, jiraProject } = action;
      const newJiraProjects = { ...state.jiraProjects, [projectId]: jiraProject };

      return { ...state, jiraProjects: newJiraProjects };
    }

    case 'JIRA_PROJECT_REMOVAL_SUCCESS': {
      const { projectId } = action;
      const { [projectId]: removedProject, ...otherJiraProjects } = state.jiraProjects;

      return { ...state, jiraProjects: otherJiraProjects };
    }

    default:
      return state;
  }
};

export default jiraProjects;
